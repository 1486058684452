import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http'
import { InitComponent } from './pages/init/init.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LocalStorageModule } from 'angular-2-local-storage-encrypt';


import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import {  MatDialog, MatDialogModule, 
          MatGridListModule, 
          MatInputModule, 
          MatProgressSpinnerModule, 
          MatRippleModule, 
          MatToolbarModule, 
          MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort'

import { LoginComponent } from './pages/usuario/login/login.component';
import { CambioClaveComponent } from './pages/usuario/cambio-clave/cambio-clave.component';
import { VerificarReciboComponent } from './pages/recibo-electronico/verificar-recibo.component';
import { DetalleServicioComponent } from './pages/servicios/detalle-servicio/detalle-servicio.component';
import { ConsultaComponent } from './pages/consulta/consulta.component';
import { ConsultaServiciosComponent } from './pages/servicios/consulta-servicios.component';
import { VisorComponent } from './pages/visor/visor.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { RecuperarClaveComponent } from './pages/usuario/recuperarClave/recuperarClave.component';


import { CrearUsuarioComponent } from './pages/usuario/crear-usuario/crear-usuario.component';
import { PagoBoletoComponent } from './pages/pago-boleto/pago-boleto.component';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { LoginService } from './pages/usuario/login/login.service';
import { ErrorInterceptor } from './interceptors/error-interceptor';
import { ConsultaService } from './pages/consulta/consulta.service';
import { CambioClaveService } from './pages/usuario/cambio-clave/cambio-clave.service';
import { RecuperarClaveService } from './pages/usuario/recuperarClave/recuperarClave.service';
import { ConsultaServiciosService } from './pages/servicios/consulta-servicios.service';
import { CuentaCorrienteComponent } from './pages/servicios/cuenta-corriente/cuenta-corriente.component';
import { AbrirLinkExternoService } from './Utilerias/abrirLinkExterno.service';
import { MensajeService } from './Utilerias/mensajes/mensaje.service';
import { ReciboElectronicoService } from './pages/recibo-electronico/recibo-electronico.service';
import { DetalleReciboComponent } from './pages/recibo-electronico/detalle-recibo/detalle-recibo.component';
import { CrearUsuarioService } from './pages/usuario/crear-usuario/crear-usuario.service';
import { PagoBoletoService } from './pages/pago-boleto/pago-boleto.service';
import { MensajeComponent } from './Utilerias/mensajes/mensaje.component';
import { HistorialPagosComponent } from './pages/historial-pagos/historial-pagos.component';
import { HistorialPagosService } from './pages/historial-pagos/historial-pagos.service';
import { VisorReciboComponent } from './pages/historial-pagos/visor/visor-recibo.component';
import { MunicipalidadService } from './components/municipalidad/municipalidad.service';
import { MunicipalidadComponent } from './components/municipalidad/municipalidad.component';
import { PagoDocumentoCobroComponent } from './pages/pago-documento-cobro/pago-documento-cobro.component';
import { PagoDocumentoCobroService } from './pages/pago-documento-cobro/pago-documento-cobro.service';
import { InformacionMunicipalidadComponent } from './pages/informacion-municipalidad/informacion-municipalidad.component';
import { DialogoAvisoComponent } from './Utilerias/dialogo-aviso/dialogo-aviso.component';
import { ConsultaMultaTransitoComponent } from './pages/consulta-multa-transito/consulta-multa-transito.component';
import { ConsultaMultaTransitoService } from './pages/consulta-multa-transito/consulta-multa-transito.service';

import { AgmCoreModule } from '@agm/core';
import { VisorMapaComponent } from './pages/visor-mapa/visor-mapa.component';
import { VisorFotoComponent } from './pages/visor-foto/visor-foto.component';
import { VisorDocumentoComponent } from './pages/visor-documento/visor-documento.component';

@NgModule({
  declarations: [
    AppComponent,
    InitComponent,
    LoginComponent,
    CambioClaveComponent,
    VerificarReciboComponent,
    DetalleServicioComponent,
    ConsultaComponent,
    ConsultaServiciosComponent,
    VisorComponent,
    InicioComponent,
    RecuperarClaveComponent,
    CrearUsuarioComponent,
    PagoBoletoComponent,
    CuentaCorrienteComponent,
    VerificarReciboComponent,
    DetalleReciboComponent,
    MensajeComponent,
    HistorialPagosComponent,
    VisorReciboComponent,
    MunicipalidadComponent,
    PagoDocumentoCobroComponent,
    InformacionMunicipalidadComponent,
    DialogoAvisoComponent,
    ConsultaMultaTransitoComponent,
    VisorMapaComponent,
    VisorFotoComponent,
    VisorDocumentoComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,

    NgxExtendedPdfViewerModule,

    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatTableModule,
    MatBottomSheetModule,
    MatDialogModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    
    LocalStorageModule.forRoot({
      prefix: 'app',
      storageType: 'localStorage',
      encryptionActive: true,
      encryptionOptions: {
          encryptionKey: 'keyForEncriptHere',
          encryptionIv: 'iVHere',
          encryptionSalt: 'saltHere'
      }
    }), 

     AgmCoreModule.forRoot({
       apiKey: 'AIzaSyBQrUAePqerJCjOHjn9FXiwSBbkjvRtXv8'
     })
  ],
  exports: [
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers:
  [ LoginService, ConsultaService, CambioClaveService, RecuperarClaveService, ConsultaServiciosService, MatDialog,
    AbrirLinkExternoService, MensajeService, ReciboElectronicoService, CrearUsuarioService, PagoBoletoService, 
    CrearUsuarioService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'accent' }},
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false} },
    HistorialPagosService, MunicipalidadService, PagoDocumentoCobroService,
    ConsultaMultaTransitoService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CuentaCorrienteComponent,
    DialogoAvisoComponent,
    VisorMapaComponent,
    VisorFotoComponent,
    VisorDocumentoComponent
  ],
  schemas:[ CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
