import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DocumentoCobroDTO, ParametrosDocumentoDeCobro, VisorDocumentoCobroDTO } from "../servicios/consultaServicioDTO";
import { ContribuyenteDTO } from './../usuario/usuarioDTO'
import { ParametrosDocumentoCobro } from "./pagoDocumentoCobroDTO";
import { isNullOrUndefined } from "util";
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt'

@Injectable()
export class PagoDocumentoCobroService {
  private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
  //private UrlApi = "api/comunicabanco";   //URL para QA y otros ambientes

  usuario: string = 'PORTALGL';
  clave: string = 'SECRETO';
  
  private headers = new HttpHeaders({
    'Content-Type': 'text/xml;charset=UTF-8'
  });

  /**
   * Definimos todo lo necesario para
   * @param http
   */
  constructor(private http: HttpClient, 
    private encryptLocalService: LocalStorageServiceEncrypt) {}

  /**
   * Realiza la consulta de los municipios del departamento
   * @param id_departamento identificador único del departamento
   */
 /**
   * Metodo que realiza la consulta al web services para generar el documento de cobro
   */
  async GenerarDocumentoCobro(parametrosDocumentoCobro: ParametrosDocumentoCobro): Promise<VisorDocumentoCobroDTO[]> {
   
    var Url = this.UrlApi + '/consultareporte';

    try {
      
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      
      let bodyParametros = {
        "ID_ENTIDAD": parametrosDocumentoCobro.ID_ENTIDAD.toString(), 
        "STR_USR": this.usuario, 
        "ID_CONTRIBUYENTE" : parametrosDocumentoCobro.NUMERO_DOCUMENTO.toString(),
        "REPORTE" : parametrosDocumentoCobro.REPORTE
      }

      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      console.log(ApiResponse.body);

      if(ApiResponse["body"]["mensaje"].includes("corrompido"))
      {
        this.encryptLocalService.set('msjCorrompido', JSON.stringify ("El monto del documento no coicide con sus detalles, ha sido corrompido y no se puede pagar."));
      }
      else
      {
        const mdlDatos = JSON.parse(ApiResponse["body"]["data"]); 
        // console.log(mdlDatos.mdlDocumento);
        //significa que todo salió bien, caso contrario que devuelva objeto nulo
        if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
        {
          // let documentoCobro: DocumentoCobroDTO = {
          //   ID_DOCUMENTO: mdlDatos.mdlDocumento["0"]["IdDocumento"],
          //   NUMERO_DOCUMENTO : mdlDatos.mdlDocumento["0"]["NumeroDocumento"],
          //   DESCRIPCION : mdlDatos.mdlDocumento["0"]["Descripcion"],
          //   FECHA_DOCUMENTO : mdlDatos.mdlDocumento["0"]["FechaDocumento"],
          //   MONTO_DOCUMENTO : mdlDatos.mdlDocumento["0"]["MontoDocumento"],
          //   ESTADO : mdlDatos.mdlDocumento["0"]["Estado"],
          //   ID_PERSONA : mdlDatos.mdlDocumento["0"]["IdPersona"],
          //   FECHA_APROBADO : mdlDatos.mdlDocumento["0"]["FechaAprobado"],
          //   FECHA_REFERENCIA : mdlDatos.mdlDocumento["0"]["FechaReferencia"],
          //   OBSERVACIONES : mdlDatos.mdlDocumento["0"]["Observaciones"],
          //   ID_DOCUMENTO_PADRE : mdlDatos.mdlDocumento["0"]["IdDocumentoPadre"],
          //   ID_TIPO_DOCUMENTO : mdlDatos.mdlDocumento["0"]["IdTipoDocumento"],
          //   ID_ENTIDAD : mdlDatos.mdlDocumento["0"]["IdEntidad"],
          //   ID_CONTRIBUYENTE : mdlDatos.mdlDocumento["0"]["IdContribuyente"],
          //   DOCUMENTO : mdlDatos.Documento_Bytes
          // } 
      
          let detallesVisorDocumento = [];
          let pintarIva = false;

          for (var i=0; i < mdlDatos.mdlVisorDocumentos.length; i++){
            let visorDocumento: VisorDocumentoCobroDTO = {
              id_documento: 0,
              numero_documento : 0,
              fecha_referencia : null,
              monto_documento : 0,         
              nombre_contribuyente : '',
              nim : '',
              nit : '',
              tarjeta : '',
              direccion : '',
              servicio : '',
              concepto : '',
              monto : 0,
              iva : 0,
              id_entidad : 0, 
              correo_electronico : '',
              id_contribuyente : 0
            }

            if(pintarIva == false)
            {
              if(mdlDatos.mdlVisorDocumentos[i]["ID_DETALLE_SERVICIO"] == 937 ||
              mdlDatos.mdlVisorDocumentos[i]["ID_DETALLE_SERVICIO"] == 938 ||
              mdlDatos.mdlVisorDocumentos[i]["ID_DETALLE_SERVICIO"] == 939 || 
              mdlDatos.mdlVisorDocumentos[i]["ID_DETALLE_SERVICIO"] == 971 ||   
              mdlDatos.mdlVisorDocumentos[i]["ID_DETALLE_SERVICIO"] == 972)
              {
                pintarIva = true;
              }    
            }
            
            visorDocumento.id_documento = mdlDatos.mdlVisorDocumentos[i]["ID_DOCUMENTO"];
            visorDocumento.numero_documento = mdlDatos.mdlVisorDocumentos[i]["NUMERO_DOCUMENTO"];
            visorDocumento.fecha_referencia = mdlDatos.mdlVisorDocumentos[i]["FECHA_REFERENCIA"];
            visorDocumento.monto_documento = mdlDatos.mdlVisorDocumentos[i]["MONTO_DOCUMENTO"];        
            visorDocumento.nombre_contribuyente = mdlDatos.mdlVisorDocumentos[i]["NOMBRE_CONTRIBUYENTE"];
            visorDocumento.nim = mdlDatos.mdlVisorDocumentos[i]["NIM"];
            visorDocumento.nit = mdlDatos.mdlVisorDocumentos[i]["NIT"];
            visorDocumento.tarjeta = mdlDatos.mdlVisorDocumentos[i]["TARJETA"];
            visorDocumento.direccion = mdlDatos.mdlVisorDocumentos[i]["DIRECCION"];
            visorDocumento.servicio = mdlDatos.mdlVisorDocumentos[i]["SERVICIO"];
            visorDocumento.concepto = mdlDatos.mdlVisorDocumentos[i]["CONCEPTO"];
            visorDocumento.monto = mdlDatos.mdlVisorDocumentos[i]["MONTO"];
            visorDocumento.iva = mdlDatos.mdlVisorDocumentos[i]["MONTO_IVA"];
            visorDocumento.id_entidad = mdlDatos.mdlVisorDocumentos[i]["ID_ENTIDAD"];
            visorDocumento.correo_electronico = mdlDatos.mdlVisorDocumentos[i]["CORREO_ELECTRONICO"];
            visorDocumento.id_contribuyente = mdlDatos.mdlVisorDocumentos[i]["ID_ENTIDAD"];
            
            detallesVisorDocumento.push(visorDocumento);
          };

          //validando el nombre del contribuyente
          //  var nombre_contribuyente = mdlDatos.mdlContribuyente["0"]["Nombre"];
          //  var correo_electronico = "";

          // if(mdlDatos.mdlContribuyente["0"]["ApellidoPaterno"] != null)
          // {
          //   nombre_contribuyente = nombre_contribuyente + " " + mdlDatos.mdlContribuyente["0"]["ApellidoPaterno"];
          // }
          
          // if(mdlDatos.mdlContribuyente["0"]["ApellidoMaterno"] != null)
          // {
          //   nombre_contribuyente = nombre_contribuyente + " " + mdlDatos.mdlContribuyente["0"]["ApellidoMaterno"];
          // }

          // if(mdlDatos.mdlContribuyente["0"]["ApellidoCasada"] != null)
          // {
          //   nombre_contribuyente = nombre_contribuyente + " " + mdlDatos.mdlContribuyente["0"]["ApellidoCasada"];
          // }

          // //validando el correo electrónico
          // if(mdlDatos.mdlContribuyente["0"]["CorreoElectronico"] != null)
          // {
          //   correo_electronico = mdlDatos.mdlContribuyente["0"]["CorreoElectronico"];
          // }

          // let contribuyente: ContribuyenteDTO = {
          //   USUARIO: null,
          //   ID_CONTRIBUYENTE: mdlDatos.mdlContribuyente["0"]["IdContribuyente"],
          //   ID_TIPO_CONTRIBUYENTE : mdlDatos.mdlContribuyente["0"]["IdTipoContribuyente"],
          //   ESTADO : mdlDatos.mdlContribuyente["0"]["Estado"],
          //   NOMBRE : mdlDatos.mdlContribuyente["0"]["Nombre"],
          //   NIM : mdlDatos.mdlContribuyente["0"]["Nim"],
          //   DPI : mdlDatos.mdlContribuyente["0"]["Dpi"],
          //   BARRIO_COLONIA_NOTIFICACION : mdlDatos.mdlContribuyente["0"]["BarrioColoniaNotificacion"],
          //   BARRIO_COLONIA_DOMICILIO : mdlDatos.mdlContribuyente["0"]["BarrioColoniaDomicilio"],
          //   CORREO_ELECTRONICO: correo_electronico,
          //   NIT : mdlDatos.mdlContribuyente["0"]["Nit"],
          //   APELLIDO_PATERNO : mdlDatos.mdlContribuyente["0"]["ApellidoPaterno"],
          //   APELLIDO_MATERNO : mdlDatos.mdlContribuyente["0"]["ApellidoMaterno"],
          //   APELLIDO_CASADA : mdlDatos.mdlContribuyente["0"]["ApellidoCasada"],
          //   MUNICIPIO_DOMICILIO : mdlDatos.mdlContribuyente["0"]["MunicipioDomicilio"],
          //   DEPARTAMENTO_DOMICILIO: mdlDatos.mdlContribuyente["0"]["DepartamentoDomicilio"],
          //   MUNICIPIO_NOTIFICACION: mdlDatos.mdlContribuyente["0"]["MunicipioNotificacion"],
          //   DEPARTAMENTO_NOTIFICACION: mdlDatos.mdlContribuyente["0"]["DepartamentoNotificacion"],
          //   DESCRIPCION: null,
          //   NOMBRE_COMPLETO: nombre_contribuyente,
          //   ID_ENTIDAD:null,
          //   REPRESENTANTE_LEGAL: mdlDatos.mdlContribuyente["0"]["RepresentanteLegal"],
          //   FALLECIDO: mdlDatos.mdlContribuyente["0"]["Fallecido"],
          //   PAGO_ONLINE: mdlDatos.mdlContribuyente["0"]["PagoOnline"],
          //   PASSWORD: mdlDatos.mdlContribuyente["0"]["Password"],
          //   FECHA_ACTIVACION_PAGO_ONLINE: mdlDatos.mdlContribuyente["0"]["FechaActivacionPagoOnline"],
          //   POR_VALIDAR: mdlDatos.mdlContribuyente["0"]["PorValidar"],
          //   PRIMER_INGRESO: mdlDatos.mdlContribuyente["0"]["PrimerIngreso"]
          // }

          // this.encryptLocalService.set('contribuyente', JSON.stringify (contribuyente));
          // this.encryptLocalService.set('detalleDoc', JSON.stringify (detallesVisorDocumento));

          this.encryptLocalService.set('columnaIVA', JSON.stringify (pintarIva));

          return detallesVisorDocumento;
        }
        else
        {
          return null;
        }
      }   
    }catch (err)
    {
      this.handleError(err);
      return null
    }
  }

  
  /**
   * Metodo para el manejo de errores
   * @param error
   */
  handleError(error: string) {
    console.log(error);
    return Observable.throw(error);
  }  
}
