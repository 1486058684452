import { Component, Inject, OnInit,ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DateAdapter, MatTableDataSource, MAT_DATE_FORMATS } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'
import { consultaMultaTransitoDTO, parametrosMultaTransito,parametrosPagoMultaTransito,consultaDetalleMultaTransitoDTO } from 'src/app/pages/consulta-multa-transito/consultaMultaTransitoDTO';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../Utilerias/date.adapter';
import { ConsultaMultaTransitoService } from   'src/app/pages/consulta-multa-transito/consulta-multa-transito.service';

@Component({
  selector: 'app-visor-foto',
  templateUrl: './visor-foto.component.html',
  styleUrls: ['./../servicios/consulta-servicios.component.scss'],
  providers: 
    [
      {provide: DateAdapter, useClass: AppDateAdapter},
      {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
    ]
})
export class VisorFotoComponent implements OnInit {
  imageSource: SafeResourceUrl = "";
  columnasEncabezadoMultas: string[] = ['nombre_municipio','numero_boleta', 'fecha_infraccion', 'monto_total', 'direccion_infraccion'];
  dataSourceMultasTransito = null;

  columnasDetalleMultas: string[] = ['articulo','inciso', 'monto', 'fecha_registro'];
  dataSourceDetalleMultasTransito = null;

  multaTransito: consultaMultaTransitoDTO[] = [];
  detallemultaTransito: consultaDetalleMultaTransitoDTO[] = [];

  @ViewChild(MatPaginator, { static:false }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(private dialogRef: MatDialogRef<VisorFotoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string, 
        private sanitizer: DomSanitizer, 
        private ConsultaMultaTransitoService : ConsultaMultaTransitoService) { }

  ngOnInit() {
    this.ConsultaDetalleMultaTransito(this.data["id_boleta"])
    this.Pintarimg();
  }

  /**
   * Metodo que arma la foto de la boleta
   */
   Pintarimg() {
    let multa: consultaMultaTransitoDTO = {
      id_boleta: 0,
      id_entidad: 0,
      nombre_municipio: "",
      nombre_departamento: "",
      serie_boleta: "",
      numero_boleta: 0,
      fecha_infraccion: "",
      monto_boleta: 0,
      marca_vehiculo: "",
      color_vehiculo: "",
      monto_descuento: 0,
      monto_total: 0,
      monto_intereses : 0,
      marcado : false,
      boleta_foto : null,
      extension_foto : "",
      direccion_infraccion : ""
    }

    multa.id_boleta = this.data["id_boleta"];
    multa.nombre_municipio = this.data["nombre_municipio"];
    multa.nombre_departamento = this.data["nombre_departamento"];
    multa.numero_boleta = this.data["numero_boleta"];
    multa.fecha_infraccion = this.data["fecha_infraccion"];
    multa.monto_boleta = this.data["monto_boleta"];
    multa.monto_descuento = this.data["monto_descuento"];
    multa.monto_intereses = this.data["monto_intereses"];
    multa.monto_total = multa.monto_boleta + multa.monto_intereses - multa.monto_descuento;
    multa.direccion_infraccion = this.data["direccion_infraccion"];

    this.multaTransito.push(multa);

    this.dataSourceMultasTransito = new MatTableDataSource<consultaMultaTransitoDTO>(this.multaTransito);
    this.dataSourceMultasTransito.sort = this.sort;
    this.dataSourceMultasTransito.paginator = this.paginator;

    this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/' + this.data["extension_foto"].toString() + ';base64,' + this.data["boleta_foto"]);
  }

  /**
   * Metodo que consulta multas de transito a tavés de una placa de vehículo
   */
  async ConsultaDetalleMultaTransito(id_boleta: number){
        try {   
          this.detallemultaTransito = await this.ConsultaMultaTransitoService.ComsultarDetalleMultaTransito(id_boleta);
          this.CargaDetallesALaTabla();
    
        } catch (error) {
          // this.mensajeService.Advertencia("No fue posible obtener información de multas de transito con la placa indicada.");
        } finally {
          // this.cargando = false;
        }
  }

  /**
   * Metodo que carga los datos a la tabla HTML
   */
  CargaDetallesALaTabla() {  
     this.dataSourceDetalleMultasTransito = new MatTableDataSource<consultaDetalleMultaTransitoDTO>(this.detallemultaTransito);
     this.dataSourceDetalleMultasTransito.sort = this.sort;
     this.dataSourceDetalleMultasTransito.paginator = this.paginator;
  }

  /**
   * Cerrar dialogo
   */
   Cerrar(){
    this.dialogRef.close();
  }

  /**
   * Metodo que manda a llamar a la función de imprimir pantalla
   */
   Imprimir() {
    window.print();
  }
}
