import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { isNullOrUndefined } from "util";
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt'
import { consultaDetalleMultaTransitoDTO, consultaMultaTransitoDTO, parametrosMultaTransito } from './consultaMultaTransitoDTO';

@Injectable()
export class ConsultaMultaTransitoService {
  private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
  //private UrlApi = "api/comunicabanco";   //URL para desa
  
  private headers = new HttpHeaders({
    'Content-Type': 'text/xml;charset=UTF-8'
  });

  /**
   * Definimos todo lo necesario para
   * @param http
   */
  constructor(private http: HttpClient, 
    private encryptLocalService: LocalStorageServiceEncrypt) {}

  
    /**
   * Realiza la consulta de las multas de transito por medio de la placa del vehículo
   * @param placa identificador de la placa del vehículo del infractor
   */
  async ComsultarMultaTransito(parametros: parametrosMultaTransito) {
    /*var datePipe = new DatePipe('en-US');
    var fechaInicial = datePipe.transform(parametros.fechaInicio, 'dd/MM/yyyy').toString();
    var fechaFinal = datePipe.transform(parametros.fechaFin, 'dd/MM/yyyy').toString();*/
    var Url = this.UrlApi + '/consultamultapmt';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    try {
      const ApiResponse = await this.http.post(Url, parametros, {observe: 'response'}).toPromise();

      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        if(ApiResponse.body["data"] instanceof Array){
          //trae data entonces se arma el arreglo con la estructura correspondiente 
          if (ApiResponse.body["data"].length > 0){
            var multasEncontradas = [];
    
            for (var i=0; i < ApiResponse.body["data"].length; i++){
              let multa: consultaMultaTransitoDTO = {
                id_boleta: 0,
                id_entidad: 0,
                nombre_municipio: "",
                nombre_departamento: "",
                serie_boleta: "",
                numero_boleta: 0,
                fecha_infraccion: "",
                monto_boleta: 0,
                marca_vehiculo: "",
                color_vehiculo: "",
                monto_descuento: 0,
                monto_total: 0,
                monto_intereses : 0,
                marcado : false,
                boleta_foto : null,
                extension_foto : "",
                direccion_infraccion : ""
              }
    
              multa.id_boleta = ApiResponse.body["data"][i][0];
              multa.id_entidad = ApiResponse.body["data"][i][1];
              multa.nombre_municipio = ApiResponse.body["data"][i][2];
              multa.nombre_departamento = ApiResponse.body["data"][i][3];
              multa.serie_boleta = ApiResponse.body["data"][i][4];
              multa.numero_boleta = ApiResponse.body["data"][i][5];
              multa.fecha_infraccion = ApiResponse.body["data"][i][6];
              multa.monto_boleta = ApiResponse.body["data"][i][7];
              multa.marca_vehiculo = ApiResponse.body["data"][i][8];
              multa.color_vehiculo = ApiResponse.body["data"][i][9];
              multa.monto_descuento = ApiResponse.body["data"][i][10];
              multa.monto_intereses = ApiResponse.body["data"][i][11];
              multa.monto_total = multa.monto_boleta + multa.monto_intereses - multa.monto_descuento;
              multa.marcado = false;
              multa.boleta_foto = ApiResponse.body["data"][i][15];
              multa.extension_foto = ApiResponse.body["data"][i][16];
              multa.direccion_infraccion = ApiResponse.body["data"][i][17];
               
              multasEncontradas.push(multa);
            } 
          return multasEncontradas;
          } 
          else 
            return null;
        }
      }
    }
    catch (err)
    {
      console.log("Consulta historial de pagos. Error: " + err)
      this.handleError("No fue posible obtener el historial de pagos");;
    }
  }

  /**
   * 
   * @param usuario Metodo
   */
   async PagosLineaEjercicio(id_entidad: number, ejercicio: number) {
    var respuesta;

    var Url = this.UrlApi + '/validapagoslinea_ejercicio';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
  
    let bodyParametros = {
      "ID_ENTIDAD": id_entidad.toString(),
      "EJERCICIO": ejercicio.toString()
    };
    
    try {
      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      respuesta = ApiResponse.body;
      return respuesta;

    }
    catch (err)
    {
      this.handleError(err);
    }
  }

  /**
   * Metodo para el manejo de errores
   * @param error
   */
  handleError(error: string) {
    console.log(error);
    return Observable.throw(error);
  }  

  /**
   * Realiza la consulta de los inciso que se infringieron en una boleta
   * @param id_boleta identificador de la boleta de PMT a pagar
   */
  async ComsultarDetalleMultaTransito(id_boleta: number) {
    var Url = this.UrlApi + '/consultadetallesmultapmt';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    try {
      let bodyParametros = {
        "ID_BOLETA": id_boleta.toString(),
      };

      const ApiResponse = await this.http.post(Url, bodyParametros, {observe: 'response'}).toPromise();

      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if(ApiResponse.status == 200 &&  !isNullOrUndefined(ApiResponse.body))
      {
        if(ApiResponse.body["data"] instanceof Array){
          //trae data entonces se arma el arreglo con la estructura correspondiente 
          if (ApiResponse.body["data"].length > 0){
            var detallesEncontrados = [];
    
            for (var i=0; i < ApiResponse.body["data"].length; i++){
              let detalleMulta: consultaDetalleMultaTransitoDTO = {
                id_detalle_boleta: 0,
                descripcion : "",
                fecha_registro : "",
                monto : "",
                articulo : 0,
                inciso : "",
              }

              detalleMulta.id_detalle_boleta = ApiResponse.body["data"][i]["idDetalleBoleta"];
              detalleMulta.descripcion = ApiResponse.body["data"][i]["descripcion"];
              detalleMulta.fecha_registro = ApiResponse.body["data"][i]["fechaRegistro"];
              detalleMulta.monto = ApiResponse.body["data"][i]["monto"];
              detalleMulta.articulo = ApiResponse.body["data"][i]["articulo"];
              detalleMulta.inciso = ApiResponse.body["data"][i]["inciso"];
               
              detallesEncontrados.push(detalleMulta);
            } 
          console.log(detallesEncontrados);
          return detallesEncontrados;
          } 
          else 
            return null;
        }
      }
    }
    catch (err)
    {
      console.log("Consulta detalle de multas de transito. Error: " + err)
      this.handleError("No fue posible obtener el detalle de la multa de transito");;
    }
  }
}
