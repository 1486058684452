import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConsultaMultaTransitoService } from './consulta-multa-transito.service';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { MatCheckbox, MatDialog } from '@angular/material';
import { consultaMultaTransitoDTO, parametrosMultaTransito,parametrosPagoMultaTransito } from './consultaMultaTransitoDTO';
import { DateAdapter, MatTableDataSource, MAT_DATE_FORMATS } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'
import { AppDateAdapter, APP_DATE_FORMATS} from '../../Utilerias/date.adapter';
import { isNull, isNullOrUndefined } from 'util';
import { AbrirLinkExternoService } from 'src/app/Utilerias/abrirLinkExterno.service';
import { copyFileSync } from 'fs';
import { VisorFotoComponent } from '../visor-foto/visor-foto.component';

interface TipoPlaca {
  value: string;
  viewValue: string;
}

interface MultaSel {
  numero_boleta: number;
  monto_total: number;
}

@Component({
  selector: 'app-consulta-multa-transito',
  templateUrl: './consulta-multa-transito.component.html',
  styleUrls: ['./../servicios/consulta-servicios.component.scss'],
  providers: 
    [
      {provide: DateAdapter, useClass: AppDateAdapter},
      {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
    ]
})
export class ConsultaMultaTransitoComponent implements OnInit {
  submitted = false;
  formConsultaMultaTransito: FormGroup;
  formConsultaMultaTransitoBoleta: FormGroup;

  cargando: boolean = false;
  multasTransito: consultaMultaTransitoDTO[] = [];
  parametrosMultaTransito: parametrosMultaTransito = {placa:"", serie_boleta:"", numero_boleta:0, tipo_busqueda:0, id_entidad:""};
  HayMultas: boolean = false;
  placa_res : string;
  marca_res : string;
  color_res : string;
  dataSourceMultasTransito = null;
  dataSourceMultasSel = null;
  columnasEncabezadoMultas: string[] = ['id_boleta','nombre_departamento','nombre_municipio', 'serie_boleta','numero_boleta', 'fecha_infraccion', 'monto_boleta', 'monto_intereses', 'monto_descuento', 'monto_total', 'boleta_foto'];
  columnasEncabezadoSel: string[] = ['numero_boleta', 'monto_total'];
  Monto_Total = 0;
  seleccionoElementoAPagar: boolean = false;
  checkedIDs = [];
  indices_checked = [];
  boletas = [];
  entidades = [];
  id_entidad_pago : number;
  tienePagosLineaEjercicio: boolean = false;
  aceptaPagosEnLinea: boolean = false;

  selectedValue: string;
  tiposPlacas: TipoPlaca[] = [
    //{value: 'SP', viewValue: '[SP] - SIN PLACAS'},
    {value: 'P', viewValue: '[P] - PARTICULAR'},
    {value: 'A', viewValue: '[A] - ALQUILER'},
    {value: 'C', viewValue: '[C] - COMERCIAL'},
    {value: 'TE', viewValue: '[TE] - TRANSPORTE EXTRAURBANO'},
    {value: 'U', viewValue: '[U] - TRANSPORTE URBANO'},
    {value: 'TRC', viewValue: '[TRC] - AGRÍCOLA, INDUSTRIAL O DE CONSTRUCCIÓN'},
    {value: 'M', viewValue: '[M] - MOTOCICLETA'},
    {value: 'MT', viewValue: '[MT] - MOTOTAXI'},
    {value: 'TC', viewValue: '[TC] - REMOLQUES Y SEMIRREMOLQUES'},
    {value: 'O', viewValue: '[O] - OFICIAL'},
    {value: 'CD', viewValue: '[CD] - CUERPO DIPLOMATICO'},
    {value: 'CC', viewValue: '[CC] - CUERPO CONSULAR'},
    {value: 'MI', viewValue: '[MI] - MISION INTERNACIONAL'},
    {value: 'DIS', viewValue: '[D] - DISTRIBUIDOR'},
  ];

  multasSel = [];
  linkPagos: string;

  @ViewChild(MatPaginator, { static:false }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(private router:Router, 
    private encryptLocalService: LocalStorageServiceEncrypt,
    private fb: FormBuilder,
    private mensajeService: MensajeService,
    public dialog: MatDialog,
    private ConsultaMultaTransitoService : ConsultaMultaTransitoService,
    private abrirLinkExternoService: AbrirLinkExternoService
    ) { }

  ngOnInit() {
    this.CrearForm();
    this.CrearFormBoleta();
    this.cargando = false;
    this.linkPagos = "https://serviciosgl.minfin.gob.gt/Integracion_Pago/frmCredencialesPMT.aspx" 
  }


  CrearForm() {
    this.formConsultaMultaTransito = this.fb.group({
      selTipoPlaca: new FormControl(new String(), Validators.required),
      txtPlaca: new FormControl(new String(), Validators.required),
    });
  }

  CrearFormBoleta() {
    this.formConsultaMultaTransitoBoleta = this.fb.group({
      txtSerieBoleta: new FormControl(new String(), Validators.required),
      txtNoBoleta: new FormControl(new String(), Validators.required),
      id_entidad: [0, [Validators.required, Validators.min(1)]]
    });
  }

  /**
   * Metodo que consulta multas de transito a tavés de una placa de vehículo
   */
   async ConsultaMultaTransito(tipoBusqueda: number){
    if(tipoBusqueda == 1)
    {
      //busqueda normal por placa
      if (this.formConsultaMultaTransito.valid) {
        this.cargando = true;
        this.parametrosMultaTransito.placa = this.formConsultaMultaTransito.value.selTipoPlaca + "-" +  this.formConsultaMultaTransito.value.txtPlaca;//"C-091BJX";
        this.parametrosMultaTransito.tipo_busqueda = 1;
        //this.formConsultaMultaTransitoBoleta.reset();
  
        try {
        
          this.multasTransito = await this.ConsultaMultaTransitoService.ComsultarMultaTransito(this.parametrosMultaTransito);
  
          if (this.multasTransito == null){
            this.mensajeService.Exito("No se encuentran datos registrados, por favor ponerse en contacto con la municipalidad");
            this.HayMultas = false;
          }else
          {
            this.placa_res = this.parametrosMultaTransito.placa;
            this.marca_res = this.multasTransito[0]["marca_vehiculo"];
            this.color_res = this.multasTransito[0]["color_vehiculo"];
  
            this.CargaLosDatosALaTabla();
            this.HayMultas = true;
          }  
    
        } catch (error) {
          this.mensajeService.Advertencia("No fue posible obtener información de multas de transito con la placa indicada.");
        } finally {
          this.cargando = false;
        }
      }
    }
    else
    {
      //nueva busqueda por boleta
      if (this.formConsultaMultaTransitoBoleta.valid) {
        this.cargando = true;
        this.parametrosMultaTransito.serie_boleta = this.formConsultaMultaTransitoBoleta.value.txtSerieBoleta;
        this.parametrosMultaTransito.numero_boleta = this.formConsultaMultaTransitoBoleta.value.txtNoBoleta;
        this.parametrosMultaTransito.tipo_busqueda = 2;
        this.parametrosMultaTransito.id_entidad = this.formConsultaMultaTransitoBoleta.value.id_entidad.toString()
        //this.formConsultaMultaTransito.reset();
        
        try {
          this.multasTransito = await this.ConsultaMultaTransitoService.ComsultarMultaTransito(this.parametrosMultaTransito);
  
          if (this.multasTransito == null){
            this.mensajeService.Exito("No se encuentran datos registrados, por favor ponerse en contacto con la municipalidad");
            this.HayMultas = false;
          }else
          {
            this.placa_res = this.parametrosMultaTransito.placa;
            this.marca_res = this.multasTransito[0]["marca_vehiculo"];
            this.color_res = this.multasTransito[0]["color_vehiculo"];
  
            this.CargaLosDatosALaTabla();
            this.HayMultas = true;
          }  
    
        } catch (error) {
          this.mensajeService.Advertencia("No fue posible obtener información de multas de transito con la placa indicada.");
        } finally {
          this.cargando = false;
        }
      }
    }     
  }

  /**
   * Metodo que carga los datos a la tabla HTML
   */
   CargaLosDatosALaTabla() {
    this.Inicializar();
     
    var i = 0;
    for (i=0; i< this.multasTransito.length; i++){
      this.boletas.push(this.multasTransito[i].id_boleta)
    }

     this.dataSourceMultasTransito = new MatTableDataSource<consultaMultaTransitoDTO>(this.multasTransito);
     this.dataSourceMultasTransito.sort = this.sort;
     this.dataSourceMultasTransito.paginator = this.paginator;
  }

  /**
   * Metodo que carga los datos a la tabla HTML de multas seleccionadas
   */
   CargaLosDatosALaTablaSeleccion() {  
    this.dataSourceMultasSel = this.multasSel;
  }

  Limpiar(){
    this.dataSourceMultasSel = null;
  }

  Inicializar(){
    this.dataSourceMultasTransito = null;
    this.dataSourceMultasSel = null;
    this.multasSel = [];
    this.HayMultas = false;
    this.Monto_Total = 0;
    this.id_entidad_pago = null;
  }

  /**
   * Metodo que retorn a la pagina desde donde fue llamada
   */
   regresar(){
    this.router.navigate([localStorage.getItem("paginaAnterior")]);
  }

   /**
   * función que filtra los datos
   * @param value dato ingresado en el filtro
   */
    public filtrar = (value: string) => {
      this.dataSourceMultasTransito.filter = value.trim().toLocaleLowerCase();
    }

  /**
   * Selección de una multa
   * @param seleccion Metodo que obtiene los datos del item seleccionado
   */
   cambiaSeleccion(seleccion: consultaMultaTransitoDTO){
    this.Limpiar();

    if(seleccion.marcado == true){ 
      if(isNullOrUndefined(this.id_entidad_pago)){
        //solo si es la primera vez se valida puesto que las siguientes veces que presionen la selección debería de estar una entidad registrada
        this.id_entidad_pago = seleccion.id_entidad;

        this.seleccionoElementoAPagar = true;
      
        this.checkedIDs.push(seleccion.id_boleta);
        this.indices_checked.push(this.boletas.indexOf(seleccion.id_boleta));

        this.Monto_Total = this.Monto_Total + seleccion.monto_total 

        //se arma la nueva tabla con las multas seleccionadas
        let multaSel: MultaSel = {
          numero_boleta : 0,
          monto_total: 0
        }

        multaSel.numero_boleta = seleccion.numero_boleta;
        multaSel.monto_total = seleccion.monto_total;

        this.multasSel.push(multaSel);
        
        this.Limpiar();
        this.CargaLosDatosALaTablaSeleccion();
      }
      else
      {
        if(this.id_entidad_pago != seleccion.id_entidad){
          //se deselecciona de manera automática y forzada
          setTimeout(() => {
            seleccion.marcado = !seleccion.marcado;
            this.mensajeService.Error("No se pueden elegir multas de tránsito de distintas municipalidades.");
          }, 0); 
        }
        else
        {
          this.seleccionoElementoAPagar = true;
      
          this.checkedIDs.push(seleccion.id_boleta);
          this.indices_checked.push(this.boletas.indexOf(seleccion.id_boleta));

          this.Monto_Total = this.Monto_Total + seleccion.monto_total 

          //se arma la nueva tabla con las multas seleccionadas
          let multaSel: MultaSel = {
            numero_boleta : 0,
            monto_total: 0
          }

          multaSel.numero_boleta = seleccion.numero_boleta;
          multaSel.monto_total = seleccion.monto_total;

          this.multasSel.push(multaSel);

          //console.log(this.multasSel);
          this.CargaLosDatosALaTablaSeleccion();
        }
      }
    }
    else
    {
      if(this.id_entidad_pago == seleccion.id_entidad){
        //si se ha deseleccionado entonces se quita del arreglo buscandolo por el indice
        const posicion: number = this.checkedIDs.indexOf(seleccion.id_boleta);
        this.checkedIDs.splice(posicion, 1);
        this.indices_checked.splice(posicion, 1);
        this.multasSel.splice(posicion, 1)

        //console.log(this.multasSel);

        this.Monto_Total = this.Monto_Total - seleccion.monto_total;

        if (this.Monto_Total == 0)
        {
          this.seleccionoElementoAPagar = false;
          this.id_entidad_pago = null;
        }
      }    
    }
  }

  /**
   * Metodo para realizar el pago del boleto de ornato
   */
   PagarMultaTransito(){
    this.submitted = true;
     try {
      //se va a validar si la entidad seleccionada para el pago de multas tiene configurado el pago en línea y gestión para el ejercicio
      this.tienePagosLineaEjercicio = false;
      this.Verifica_PagosLineaEjercicio(this.id_entidad_pago);   
     } catch (error) {
       this.mensajeService.Error("Se ha producido un inconveniente para realizar el pago, por favor intente mas tarde");
     }
   }

   /**
   * Metodo que hace la consulta de si la municipalidad tienen prorroga de boleto de ornato
   */
    async Verifica_PagosLineaEjercicio(id_entidad:number){
      var fecha = new Date();
      var año = fecha.getFullYear();
      
      try {
        this.tienePagosLineaEjercicio = await this.ConsultaMultaTransitoService.PagosLineaEjercicio(id_entidad, año);

        if (!this.tienePagosLineaEjercicio)  
          this.mensajeService.Informacion("No se puede llevar a cabo el pago de la o las boletas debido a que la municipalidad no está habilitada para pagos en línea.");
        else
        {
          let parametrosPagoPMT: parametrosPagoMultaTransito = {
            id_entidad: this.id_entidad_pago.toString(),
            monto_cobrar: this.Monto_Total.toFixed(2),
            id_boletas: this.checkedIDs
           }
           
           this.abrirLinkExternoService.post(parametrosPagoPMT, this.linkPagos);   
        }      
      }
      catch(err){
        this.mensajeService.Error("No fue posible verificar si hay configuración de pagos en línea para la entidad y el ejercicio");
      }    
    }

   /**
   * Metodo que consulta multas de transito a tavés de una placa de vehículo
   */
    AbrirMapa(seleccion: consultaMultaTransitoDTO){
      try {      
         const dialogRef = this.dialog.open(VisorFotoComponent, {
           width: '80%',
           data:  seleccion,
           height: '90%'
         });
      } catch (error) {
        console.log("fail" + error);
        this.mensajeService.Advertencia("No fue posible carga la ubicación del punto de cobro.");
      } finally {
        
      }
  }

  /**
 * Metodo que recibe la entidad o municipalidad seleccionada
 * @param valor 
 */
  AsignaEntidad(id_entidad: number){
    this.formConsultaMultaTransitoBoleta.patchValue({id_entidad: id_entidad});
  }

}
