import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AbrirLinkExternoService } from 'src/app/Utilerias/abrirLinkExterno.service';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { ConsultaServiciosService } from '../servicios/consulta-servicios.service';
import { DocumentoCobroDTO, ParametrosPago, VisorDocumentoCobroDTO } from '../servicios/consultaServicioDTO';
import { ContribuyenteDTO } from '../usuario/usuarioDTO';
import { DateAdapter, MatTableDataSource, MAT_DATE_FORMATS } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'
import { AppDateAdapter, APP_DATE_FORMATS} from '../../Utilerias/date.adapter';
import { ContentObserver } from '@angular/cdk/observers';
import { Console } from 'console';

@Component({
  selector: 'app-visor-documento',
  templateUrl: './visor-documento.component.html',
  styleUrls: ['./../servicios/consulta-servicios.component.scss'],
  providers: 
    [
      {provide: DateAdapter, useClass: AppDateAdapter},
      {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
    ]
})
export class VisorDocumentoComponent implements OnInit {
  public src: Blob;
  public titulo: string = "Documento de cobro"; //TODO: Hay que cambiarlo por el parametro enviado para el docomento que se muestre
  aceptaPagosEnLinea: boolean = false;
  datos: VisorDocumentoCobroDTO[];
  contribuyente: ContribuyenteDTO;
  detallesDocumento: VisorDocumentoCobroDTO[] = [];

  linkPagos: string = 'https://serviciosgl.minfin.gob.gt/Integracion_Pago/frmCredenciales.aspx';
  tienePagosLineaEjercicio: boolean = false;

  columnasVisorDocumento: string[];
  dataSourceVisorDocumento = null;

  formDatosDocumento: FormGroup;
  numero_documento : string;
  fecha_referencia : string;
  monto_total : string;
  nombre_contribuyente : string;
  nim : string;
  nit : string;

  pintarIVA : boolean;

  @ViewChild(MatPaginator, { static:false }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(private router:Router,
    private dialogRef: MatDialogRef<VisorDocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private consultaServiciosService: ConsultaServiciosService,
    private mensajeService: MensajeService,
    private encryptLocalService: LocalStorageServiceEncrypt,
    private abrirLinkExternoService: AbrirLinkExternoService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.CrearForm();
    this.ReconstruyeLosParametros();
    this.AplicaPagosOnLine();
    this.DesplegarDocumentoDeCobroEnTabla();
  }

  CrearForm() {
    this.formDatosDocumento = this.fb.group({
      txtNumeroDocumento: new FormControl(new String(), Validators.required),
      txtFechaVencimiento: new FormControl(new String(), Validators.required),
    });
  }

   /**
   * Metodo que reconstruye los parametros (documento de cobro)
   */
   ReconstruyeLosParametros() {
    this.datos = JSON.parse(this.data);
    // this.contribuyente = JSON.parse(this.encryptLocalService.get('contribuyente'));
    // this.detallesDocumento = JSON.parse(this.encryptLocalService.get('detalleDoc'));
    this.pintarIVA = JSON.parse(this.encryptLocalService.get('columnaIVA'));

    if(this.pintarIVA)
      this.columnasVisorDocumento = ['tarjeta', 'direccion', 'servicio', 'concepto', 'monto', 'iva'];
    else
      this.columnasVisorDocumento = ['tarjeta', 'direccion', 'servicio', 'concepto', 'monto'];
  }

  regresar(){
    this.router.navigate(['/consulta-servicios']);
  }

  /**
   * Metodo que valida que la municipalidad pueda recibir pagos en linea
   */
  async AplicaPagosOnLine() {
    try {
      this.aceptaPagosEnLinea = await this.consultaServiciosService.AplicaPagosEnLinea(this.datos[0].id_entidad);

      if (this.aceptaPagosEnLinea)
        this.Verifica_PagosLineaEjercicio(this.datos[0].id_entidad);
      this.consultaServiciosService.aplicaPagosEnLinea$.emit(this.aceptaPagosEnLinea);
    }
    catch(err){
      this.mensajeService.Error("No fue posible verificar si la municipalidad acepta pagos en linea." + err);
    }
  }

  /**
   * Metodo que hace la consulta de si la municipalidad tienen prorroga de boleto de ornato
   */
   async Verifica_PagosLineaEjercicio(id_entidad:number){
    var fecha = new Date();
    var año = fecha.getFullYear();

    try {
      this.tienePagosLineaEjercicio = await this.consultaServiciosService.PagosLineaEjercicio(id_entidad, año);

      if (!this.tienePagosLineaEjercicio)  
        this.mensajeService.Informacion("No se puede llevar a cabo el pago de documento de cobros debido a que la municipalidad no está habilitada para pagos en línea.");
    }
    catch(err){
      this.mensajeService.Error("No fue posible verificar si hay configuración de pagos en línea para la entidad y el ejercicio");
    }    
  }

   /**
   * Metodo que carga los datos a la tabla HTML
   */
   DesplegarDocumentoDeCobroEnTabla() {  
    this.numero_documento = this.datos[0].numero_documento.toString();
    this.fecha_referencia = this.datos[0].fecha_referencia.toString();
    this.monto_total = this.datos[0].monto_documento.toString();
   
    if(this.datos[0].nombre_contribuyente == null)
      this.nombre_contribuyente = '';
    else
      this.nombre_contribuyente = this.datos[0].nombre_contribuyente.toString();

    if(this.datos[0].nim == null)
      this.nim = '';
    else
      this.nim = this.datos[0].nim.toString();

    if(this.datos[0].nit == null)
      this.nit = '';
    else
      this.nit = this.datos[0].nit.toString();

    this.dataSourceVisorDocumento = new MatTableDataSource<VisorDocumentoCobroDTO>(this.datos);
    this.dataSourceVisorDocumento.sort = this.sort;
    this.dataSourceVisorDocumento.paginator = this.paginator;
 }

  /**
   * Metodo que abre la interfaz para el pago de los servicios
   */
  RealizarPagoDocumentoCobro(){
    //this.documentoCobro = JSON.parse(this.encryptLocalService.get('documentoCobro')); //Recuperamos los parametros de la consulta
    //localStorage.removeItem('documentoCobro');

    try {
      //var param = {'id_entidad':'33', 'no_documento':'5451812', 'monto_documento':'104.81','id_contribuyente':'82991', 'contribuyente':'LETICIA MARQUEZ ROCHE MADRID'};
      console.log("click en pago de documento " + this.datos[0].correo_electronico + " // " + this.datos[0].nombre_contribuyente + " // " + (this.datos[0].nombre_contribuyente ==null ? "" : this.datos[0].nombre_contribuyente)) ;
      if (this.datos[0].monto_documento > 0){
        let parametrosPago: ParametrosPago = {
          id_entidad: this.datos[0].id_entidad,
          no_documento: this.datos[0].numero_documento,
          monto_documento: this.datos[0].monto_documento,
          id_contribuyente: this.datos[0].id_contribuyente,
          contribuyente: this.datos[0].nombre_contribuyente,
          correo: this.datos[0].correo_electronico
        }
        
        this.abrirLinkExternoService.post(parametrosPago, this.linkPagos);
        
      }
      else
      {
        this.mensajeService.Advertencia("No existe información para realizar el cobro");
      }
      
    } catch (error) {
      console.log("No se pudo redirecciónar a " + this.linkPagos + " error: " + error);
      this.mensajeService.Error("Se ha producido un inconveniente para realizar el pago, por favor intente mas tarde");
    }
    
  }

  /**
   * Cerrar dialogo
   */
  Cerrar(){
    this.dialogRef.close();
  }

}
