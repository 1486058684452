import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntidadDTO } from './municipalidadDTO';
import { consultaPuntoCobroDTO, consultaBancaMuniDTO } from 'src/app/pages/informacion-municipalidad/consultaPuntoCobroDTO';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { isNullOrUndefined } from "util";
import { isNull } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class MunicipalidadService {
  private UrlSOAPComunicaBanco = "comunicabanco/comunicabanco.asmx?op=";

  private UrlApi = "Coregl/api/comunicabanco";   //URL para QA y otros ambientes
  //private UrlApi = "api/comunicabanco";   //URL para desa

  /**
   * Obtiene las municipalidades o entidades que tienen convenio con entidad emisora de tarjetas
   */
  private EntidadesQueAceptanPagos = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <ObtenerEntidadesPago xmlns="http://tempuri.org/">
        <intId_Departamento>|ID_DEPARTAMENTO|</intId_Departamento>
      </ObtenerEntidadesPago>
    </soap12:Body>
  </soap12:Envelope>`;

  constructor(private http: HttpClient) { }

  /**
   * Realiza la consulta de los municipios que cuentan con convenio de pago por tarjeta
   * @param id_departamento identificador único del departamento
   */
  async ObtenerEntidadesAceptanPagoEnLinea() {
    //var Url = this.UrlSOAPComunicaBanco + 'ObtenerEntidadesPago';

    var Url = this.UrlApi + '/obtieneentidadespago';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    let bodyParametros = {
      "ID_DEPARTAMENTO": '0'
    };

    try {

      const ApiResponse = await this.http.post(Url, bodyParametros, { observe: 'response' }).toPromise();

      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if (ApiResponse.status == 200 && !isNullOrUndefined(ApiResponse.body)) {
        let detalles = [];
        if (ApiResponse.body["data"] instanceof Array) {
          //trae data entonces se arma el arreglo con la estructura correspondiente    
          for (var i = 0; i < ApiResponse.body["data"].length; i++) {
            let detalle: EntidadDTO = {
              ID_ENTIDAD: 0,
              DESCRIPCION: "",
              ID_DEPARTAMENTO: 0,
              DEPARTAMENTO: "",
              SITIO_WEB: ""
            }

            detalle.ID_ENTIDAD = ApiResponse.body["data"][i][0].toString();
            detalle.DESCRIPCION = ApiResponse.body["data"][i][1];
            detalle.SITIO_WEB = ApiResponse.body["data"][i][2];
            detalle.ID_DEPARTAMENTO = ApiResponse.body["data"][i][3].toString();
            detalle.DEPARTAMENTO = ApiResponse.body["data"][i][4];

            detalles.push(detalle);
          }
        }

        return detalles;
      }
      else
        return null;
    }
    catch (err) {
      this.handleError(err);
    }
  }

  /**
   * Realiza la consulta de los puntos de cobro por entidad
   * @param ID_ENTIDAD identificador único de la entidad
   */
  async ObtenerPuntosCobro(ID_ENTIDAD: number) {
    var Url = this.UrlApi + '/obtienepuntoscobroentidad';

    //encabezado del api con coregl
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    let bodyParametros = {
      "ID_ENTIDAD": ID_ENTIDAD
    };

    try {
      const ApiResponse = await this.http.post(Url, bodyParametros, { observe: 'response' }).toPromise();

      //significa que todo salió bien, se devuelve el entero del error o el conjunto de datos
      if (ApiResponse.status == 200 && !isNullOrUndefined(ApiResponse.body)) {
        let puntosCobro = [];
        let bancasMuni = [];
        let modelo = [];

        if (ApiResponse.body["data"].modeloPuntoCobro instanceof Array) {
          //trae data entonces se arma el arreglo con la estructura correspondiente    
          for (var i = 0; i < ApiResponse.body["data"].modeloPuntoCobro.length; i++) {
            let puntoCobro: consultaPuntoCobroDTO = {
              ID_PUNTO_COBRO: 0,
              DESCRIPCION: "",
              DIRECCION: "",
              WHATSAPP: 0,
              CORREO_ELECTRONICO: "",
              TELEFONO: 0,
              HORARIO_ATENCION: "",
              COBROS_POS: "",
              UBICACION_MAPA: "",
            }

            puntoCobro.ID_PUNTO_COBRO = ApiResponse.body["data"].modeloPuntoCobro[i]["idPuntoCobro"].toString();
            puntoCobro.DESCRIPCION = ApiResponse.body["data"].modeloPuntoCobro[i]["descripcion"];
            puntoCobro.DIRECCION = ApiResponse.body["data"].modeloPuntoCobro[i]["direccion"];
            puntoCobro.WHATSAPP = ApiResponse.body["data"].modeloPuntoCobro[i]["whatsapp"].toString();
            puntoCobro.CORREO_ELECTRONICO = ApiResponse.body["data"].modeloPuntoCobro[i]["correoElectronico"];
            puntoCobro.TELEFONO = ApiResponse.body["data"].modeloPuntoCobro[i]["telefono"];
            puntoCobro.HORARIO_ATENCION = ApiResponse.body["data"].modeloPuntoCobro[i]["horarioAtencion"].toString();
            puntoCobro.COBROS_POS = ApiResponse.body["data"].modeloPuntoCobro[i]["cobrosPos"];
            puntoCobro.UBICACION_MAPA = ApiResponse.body["data"].modeloPuntoCobro[i]["ubicacionMapa"];

            puntosCobro.push(puntoCobro);
          }
        }

        if (ApiResponse.body["data"].modeloBancaMuni instanceof Array) {
          //trae data entonces se arma el arreglo con la estructura correspondiente    
          for (var i = 0; i < ApiResponse.body["data"].modeloBancaMuni.length; i++) {
            let bancaMuni: consultaBancaMuniDTO = {
              COD_BANCO: 0,
              COBROS_BANCA_VIRTUAL: "",
              AGENCIAS_MUNICIPALES: "",
              AGENCIAS_LOCALES: "",
              AGENCIAS_NACIONALES: "",
              NOMBRE: "",
            }

            bancaMuni.COD_BANCO = ApiResponse.body["data"].modeloBancaMuni[i][0].toString();
            bancaMuni.COBROS_BANCA_VIRTUAL = ApiResponse.body["data"].modeloBancaMuni[i][1];
            bancaMuni.AGENCIAS_MUNICIPALES = ApiResponse.body["data"].modeloBancaMuni[i][2];
            bancaMuni.AGENCIAS_LOCALES = ApiResponse.body["data"].modeloBancaMuni[i][3].toString();
            bancaMuni.AGENCIAS_NACIONALES = ApiResponse.body["data"].modeloBancaMuni[i][4].toString();
            bancaMuni.NOMBRE = ApiResponse.body["data"].modeloBancaMuni[i][5];

            bancasMuni.push(bancaMuni);
          }
        }

        modelo.push(puntosCobro)
        modelo.push(bancasMuni)

        return modelo;
      }
      else
        return null;
    }
    catch (err) {
      this.handleError(err);
    }
  }

  /**
   * Metodo para el manejo de errores
   * @param error
   */
  handleError(error: string) {
    console.log(error);
    return Observable.throw(error);
  }
}
