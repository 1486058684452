import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntidadDTO } from '../../components/municipalidad/municipalidadDTO';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MensajeService } from 'src/app/Utilerias/mensajes/mensaje.service';
import { ConsultaServiciosService } from '../servicios/consulta-servicios.service';
import { ParametrosDocumentoCobro } from './pagoDocumentoCobroDTO';
import { DepartamentoDTO } from '../consulta/consultaDTO';
import { DocumentoCobroDTO, VisorDocumentoCobroDTO } from '../servicios/consultaServicioDTO';
import { PagoDocumentoCobroService } from './pago-documento-cobro.service';
import { ConsultaServiciosComponent } from '../servicios/consulta-servicios.component';
import { MatDialog } from '@angular/material';
import { VisorComponent } from '../visor/visor.component';
import { VisorDocumentoComponent } from '../visor-documento/visor-documento.component';

@Component({
  selector: 'app-pago-documento-cobro',
  templateUrl: './pago-documento-cobro.component.html',
  styleUrls: ['./pago-documento-cobro.component.scss'],
})
export class PagoDocumentoCobroComponent implements OnInit {
  submitted = false;
  formDocumentoCobro: FormGroup;
  departamentos: DepartamentoDTO[];
  entidades: EntidadDTO[];
  entidadesDeptoSeleccionado: EntidadDTO[];
  aplicaPagosEnLinea: boolean = false;
  muestraSpinner: boolean = false;
  parametrosDocumentoCobro: ParametrosDocumentoCobro;
  documentoCobro: VisorDocumentoCobroDTO[];
  consultaServiciosComponent: ConsultaServiciosComponent;
  cargando: boolean = false;

  @Input() id_entidad: number = 0;

  constructor(private router:Router, 
    private encryptLocalService: LocalStorageServiceEncrypt,
    private formBuilder: FormBuilder,
    private mensajeService: MensajeService,
    private EntidadService: ConsultaServiciosService,
    private pagoDocumentoCobroService: PagoDocumentoCobroService,
    public dialog: MatDialog
    ) {  }

  ngOnInit() {
    //this.ObtenerEntidades();
    this.CrearForm();
    this.parametrosDocumentoCobro = {
      ID_ENTIDAD : 0,
      NUMERO_DOCUMENTO : 0,
      REPORTE: "DETALLE_DOCUMENTO_COBRO"
    }

    this.cargando = false;
  }

  get f() { return this.formDocumentoCobro.controls; }

  /**
   * Metodo que crea el form de la página
   */
  CrearForm() {
    this.formDocumentoCobro = this.formBuilder.group({numero_documento: ['']});
  }


  /**
   * Metodo que recibe la entidad o municipalidad seleccionada
   * @param valor 
   */
  AsignaEntidad(id_entidad: number){
    this.parametrosDocumentoCobro.ID_ENTIDAD = id_entidad;
  }


  /**
   * Metodo que obtiene el documento de cobro
   */
  async ObtenerDocumentoCobro(){

    if (this.formDocumentoCobro.valid) {

      this.cargando = true;

      this.parametrosDocumentoCobro.NUMERO_DOCUMENTO = this.formDocumentoCobro.value.numero_documento;
      try {
      
        this.documentoCobro = await this.pagoDocumentoCobroService.GenerarDocumentoCobro(this.parametrosDocumentoCobro);

        if (this.documentoCobro == null){
          if(JSON.parse(this.encryptLocalService.get('msjCorrompido')) != "")
            this.mensajeService.Informacion(this.encryptLocalService.get('msjCorrompido'));
          else
            this.mensajeService.Informacion("El documento ingresado no existe, esta vencido o ya fue pagado. Verifique e intente nuevamente");
          return false;
        }else
        {
          this.EnviaReporteAVisor(this.documentoCobro);
          // this.documentoCobro.DOCUMENTO = null;  //Quitamos el documento de cobro para liberar memoria
          this.encryptLocalService.set('documentoCobro', JSON.stringify (this.documentoCobro));
        }   
  
      } catch (error) {
        this.mensajeService.Advertencia("No fue posible obtener el documento de cobro.");
      } finally {
        this.cargando = false;
      }
    }
  }

  /**
   * Abre la interfaz para ver el documento generado en una ventana modal
   * @param documento documento a visualizar
   */
   public EnviaReporteAVisor(documento:VisorDocumentoCobroDTO[]) {
    // const dialogRef = this.dialog.open(VisorComponent, {
      const dialogRef = this.dialog.open(VisorDocumentoComponent, {
      width: '60%',
      data:  JSON.stringify(documento),
      height: '75%'
    });
  }

  /**
   * Retorna a la pagina principal, debido a que solo de ahi pudo haber sido llamado
   */
  Cerrar(){
    this.router.navigate(['/'])
  }
}
